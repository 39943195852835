var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('cabecalho-pagina',{attrs:{"titulo":_vm.$t('modulos.grandeza_fisica.titulos.listagem'),"total-registros":_vm.tabela.quantidadeItens,"titulo-botao-novo":_vm.$t('modulos.grandeza_fisica.titulos.novo'),"sem-botao-ok":!_vm.buscarPermissao('GrandezaFisica', 'Inserir'),"sem-filtro":true},on:{"abrirNovo":_vm.abrirNovo}}),(
      _vm.podeExcluir &&
      _vm.participanteLogadoConta &&
      _vm.buscarPermissao('GrandezaFisica', 'Deletar')
    )?_c('botao-padrao',{staticClass:"my-2",attrs:{"outlined":"","color":"danger","disabled":!_vm.participanteLogadoConta},on:{"click":_vm.excluirRegistros}},[_c('v-icon',[_vm._v("$mdiTrashCanOutline")]),_vm._v(" "+_vm._s(_vm.$t('geral.botoes.excluir'))+" ")],1):_vm._e(),_c('tabela-padrao-prime-vue',{staticClass:"mt-2",attrs:{"filtros-com-colunas":"","colunas":_vm.tabela.colunas,"dados":_vm.tabela.dados,"mostrar-acoes":true,"selecionar-colunas":"","mostrar-seletor":_vm.buscarPermissao('GrandezaFisica', 'Deletar'),"quantidade-itens":_vm.tabela.quantidadeItens,"por-pagina":_vm.tabela.porPagina,"quantidade-paginas":_vm.tabela.quantidadePaginas},on:{"paginar":_vm.listarRegistros,"filtrarGlobal":_vm.filtrarGlobal,"filtroGlobal":(v) => {
        _vm.filtroAplicado.filter = v;
      },"nova-ordenacao":_vm.ordenacaoColuna},scopedSlots:_vm._u([{key:"acoes",fn:function({ slotProps }){return [(_vm.buscarPermissao('Participante', 'Editar'))?_c('dropdown-padrao',{attrs:{"text":"","color":"secondary"},scopedSlots:_vm._u([{key:"botao",fn:function(){return [_c('v-icon',[_vm._v(" $dotsVertical ")])]},proxy:true}],null,true)},[_c('div',[_c('dropdown-padrao-item',{attrs:{"text":"","color":"secondary"},on:{"click":function($event){return _vm.abrirEditar(slotProps.data)}}},[_vm._v(" "+_vm._s(_vm.$t('geral.botoes.editar'))+" ")])],1)]):_vm._e()]}}]),model:{value:(_vm.tabela.selecionados),callback:function ($$v) {_vm.$set(_vm.tabela, "selecionados", $$v)},expression:"tabela.selecionados"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }