<template>
  <div>
    <div>
      <slot name="titulo">
        <div
          :class="{ 'align-start': comSelect, 'align-center': !comSelect }"
          class="d-flex justify-space-between"
        >
          <div>
            <div class="titulo-pagina">
              {{ titulo }}
            </div>
            <div v-if="temSubtitulo" class="subtitulo-pagina">
              {{ subtitulo }}
            </div>
            <div
              v-if="!semRegistros"
              class="quantidade"
            >
              {{
                $tc('componentes.cabecalho_pagina.registros', totalRegistros, {
                  count: totalRegistros,
                })
              }}
            </div>
          </div>
          <div class="flex px-0 px-md-15 d-flex align-center justify-start">
            <v-chip
              v-for="(item, i) in filtros()"
              v-show="item"
              :key="`filtro-${i}`"
              close
              color="secondary"
              label
              outlined
              class="mx-1"
              @click:close="removerItemFiltro(i)"
            >
              {{ textoFiltro(item) }}
            </v-chip>
          </div>
          <div
            :class="`d-flex ${
              juntarBotoes ? 'flex-row' : 'flex-column'
            } align-end`"
          >
            <slot name="select" />

            <div
              v-if="!semBotoes"
              class="d-flex"
            >
              <slot name="botoes">
                <botao-padrao
                  v-if="!semFiltro"
                  :tooltip="$t('geral.tips.filtros')"
                  color="secondary"
                  outlined
                  class="mr-2"
                  @click="abrirModal"
                >
                  <v-icon> $filterOutline </v-icon>
                </botao-padrao>
                <modal-padrao
                  ref="modal-filtro"
                  :titulo="$t('geral.titulos.filtro')"
                  :titulo-ok="$t('geral.botoes.aplicar')"
                  direita
                  @ok="aplicarFiltro"
                  @keydown.enter="aplicarFiltro"
                  @cancelar="cancelarFiltro"
                >
                <div id="filtro">
                  <slot
                    name="filtro"
                    :filtro="filtro"
                  />
                </div>
                </modal-padrao>
                <botao-padrao
                  v-if="!semBotaoOk"
                  @click="abrirNovoFormulario"
                >
                  <v-icon>$mdiPlusThick</v-icon>
                  {{ tituloBotaoNovo }}
                </botao-padrao>
              </slot>
            </div>
            <slot name="botaoSecundario" />
          </div>
        </div>
      </slot>
    </div>
    <div>
      <slot name="botoes" />
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import moment from 'moment';
import helpers from '@common/utils/helpers';
// import { EventTypes, useEmitterBus } from '@plugins/emitter-bus.js';

export default {
  props: {
    titulo: { type: String, default: '' },
    subtitulo: { type: String, default: '' },
    temSubtitulo: { type: Boolean, default: false },
    tituloBotaoNovo: {
      type: String,
      default: function () {
        return this.$t('geral.botoes.novo');
      },
      },
      criarNovo:{
        type:Boolean,
        default:true
      },
    form: {},
    totalRegistros: { type: Number, default: 0 },
    semFiltro: { type: Boolean, default: false },
    semBotoes: { type: Boolean, default: false },
    semBotaoOk: { type: Boolean, default: false },
    comSelect: { type: Boolean, default: false },
    semRegistros: { type: Boolean, default: false },
    juntarBotoes: { type: Boolean, default: false },
    dateOnly: { type: Boolean, default: false },
    filtroPreDefinido: { type: String, default: '' },
  },
  data() {
    return {
      filtro: {},
      filtroAplicado: { filter: '' },
      // emitterGlobal: useEmitterBus(),
    };
  },
  created() {
    const filtro = this.buscarStateFiltros()?.filtroAplicado ?? {};
    this.filtroAplicado = _.cloneDeep(filtro);
    this.filtro = _.cloneDeep(filtro);
  },
  mounted() {
    if (
      this.filtroPreDefinido &&
      Object.keys(this.filtroAplicado).length === 0
    ) {
      this.filtroAplicado = { text: this.filtroPreDefinido };
    }
  },
  methods: {
    abrirNovoFormulario: function () {
      if(!this.criarNovo){
        this.$router.push({path:'/erropermissao'})
      } else{
        this.$emit('abrirNovo')
      }
    },
    abrirModal: function () {
      this.$refs['modal-filtro'].abrirModal();
    },
    aplicarFiltro: function (novoFiltro) {
      const filtro = _.cloneDeep(novoFiltro);
      this.filtroAplicado = _.cloneDeep(this.filtro);

      if(!!novoFiltro && Object.keys(filtro).length != 0)
        this.filtroAplicado = _.cloneDeep(filtro);


      this.$refs['modal-filtro'].fecharModal();
      this.emitirFiltro();
    },
    cancelarFiltro: function () {
      this.$refs['modal-filtro'].fecharModal();
      this.filtro = _.cloneDeep(this.filtroAplicado);
    },
    removerItemFiltro: function (item) {
      this.filtroAplicado[item] = null;
      this.filtro = _.cloneDeep(this.filtroAplicado);
      this.emitirFiltro();
    },
    emitirFiltro: function () {
      let filtroFormatado = {};
      Object.keys(this.filtroAplicado).forEach((key) => {
        let valor = this.valorFiltro(this.filtroAplicado[key]);
        if (valor) filtroFormatado[key] = valor;
        if (valor === false) filtroFormatado[key] = valor;
      });
      this.$emit('filtrar', filtroFormatado);
      // this.emitterGlobal.emit(EventTypes.FiltroGrid);
    },
    textoFiltro: function (item) {
      if (!item) return '';
      const ehData = moment(item, moment.ISO_8601, true).isValid();

      if (ehData) {
        const parteData = item.split('-');
        const ano = parseInt(parteData[0]);
        const mes = parseInt(parteData[1]);
        const dia = parteData[2] ? parseInt(parteData[2]) : null;

        if (!isNaN(ano) && !isNaN(mes)) {
          if (dia !== null && !isNaN(dia)) {
            return helpers.formatarDataBr(item);
          } else {
            const dataFormatada = moment({ ano, mes: mes - 1, dia: 1 }).format(
              'MM/YYYY'
            );
            return dataFormatada;
          }
        }
      }
      if (item?.text == 'true') return 'Sim';
      if (item?.text == 'false') return 'Não';
      if (item == 'true') return 'Sim';
      if (item == 'false') return 'Não';

      return item.text || item;
    },
    buscarStateFiltros() {
      return this.$store.getters['Filtros/filtros'][this.$route.name];
    },
    valorFiltro: function (item) {
      if (!item) return '';
      const ehData = moment(item, moment.ISO_8601, true).isValid();

      if (ehData) {
        const parteData = item.split('-');
        const ano = parseInt(parteData[0]);
        const mes = parseInt(parteData[1]);

        if (!isNaN(ano) && !isNaN(mes)) {
          const dia = parteData.length === 3 ? parseInt(parteData[2]) : 1;
          return helpers.formatarDataUS(`${ano}-${mes}-${dia}`);
        }
        return helpers.formatarDataUS(item);
      }

      if (typeof item.value === 'boolean') return item ? item.value : '';

      return item.value || item;
    },
    filtros() {
      delete this.filtroAplicado.filter;
      return this.filtroAplicado;
    }
  },
};
</script>
<style lang="scss">
.quantidade {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}
.subtitulo-pagina{
  color:#364054;
  font-size: 0.8rem;
  margin-bottom: 1rem;
  opacity: 0.7;
}

#filtro {
  .row {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
}
</style>
